
	import Vue from "vue";
	import Searcher from "../../components/Content/Searcher.vue";

	export default Vue.extend({
		name: "TitleWelcome",

		props: {
			title: {
				type: String,
				default: "welcomeMsg",
			},
			subtitle: {
				type: String,
				default: "welcomeMsgDashboard",
			},
			type: {
				type: String,
				default: "title-1",
			},
			showSubtitle: {
				type: Boolean,
				default: true,
			},
			showSearcher: {
				type: Boolean,
				default: false,
			},
		},

		components: { Searcher },

		data: () => ({}),

		created() {},

		mounted() {},

		computed: {},

		methods: {},
	});
