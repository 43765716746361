
// @ts-ignore
import IconBase from "@/components/Commons/Icons/IconBase.vue";
export default {
	name: "Searcher",
	props: {
		items: {
			type: Array,
			default: function () {
				return [];
			},
		},
	},
	data: function () {
		return {
			term: "",
		};
	},
	components: { IconBase },
	mounted() {},
	computed: {
		isLoggedIn() {
			return this.$store.state.auth.loggedIn;
		},
		width() {
			switch (this.$vuetify.breakpoint.name) {
				case "xs":
					return "width: 100%";
				case "sm":
					return "width: 100%";
				case "md":
					return "width: 50%";
				case "lg":
					return "width: 40%";
				case "xl":
					return "width: 30%";
			}
		},
	},
	methods: {},
};
